import React from 'react'
import '../css/ResultsTitle.scss'

/**
 * The title displaying the selected option
 * @param {string} text display the proper selection 
 * @returns {object} the title wrapped by the h1
 */
const ResultsTitle = ({ text }) => {
    if (!text) return null
    const resultTitle = text.split('"')
    const eResultTitle = decodeURI(resultTitle[1])

    return (
        <h1 className="sps2-serp_title mds-font_header--2b">
            {resultTitle[0]}
            <em className="mds-font_header--3">{'″' + eResultTitle + '″'}</em>
        </h1>
    )
}

export default ResultsTitle
