import React, { useContext } from 'react'
import { Translations } from '../services/TranslationService'
import Selectors from './Selectors'
import Button from '@3mcom/mds-library/dist/node/Button'
import Alert from '@3mcom/mds-library/dist/node/Alert'
import { trackPDPData } from '../services/TealiumService'
import { StateContext } from '../services/StateService'

const SelectorsContainer = ({
    handleReset,
    prodOptButtonRef,
    handleProductSelectorRequest,
    getNumberOfOptions,
    clearAll
}) => {
    const translations = useContext(Translations)
    const { selectedOptions, moreOptions, focusViewProductOptions, errors } =
        useContext(StateContext)

    const buttonText =
        selectedOptions?.totalSelected === moreOptions?.totalSelected
            ? `${moreOptions?.totalSelected} ${translations.productOptions}`
            : `${selectedOptions?.totalSelected} ${translations.matching} ${translations.of} ${moreOptions?.totalSelected} ${translations.productOptions}`

    return (
        <div className="sps2-pdp_pSelector--selectors">
            {clearAll && (
                <button
                    className="mds-link_primary sps2-pdp_pSelector--selectors_clear"
                    onClick={() => {
                        handleReset()
                        trackPDPData(
                            `PDP Buying Zone Selectors Link Click: ${translations.clearAll}`,
                            'Engagement Event'
                        )
                    }}
                >
                    {translations.clearAll}
                </button>
            )}
            <Selectors />
            <div className="sps2-pdp_pSelector--selectors_btn--container">
                <Button
                    tealiumTracking={true}
                    tealiumObj={{
                        event_name: `PDP Buying Zone Selectors Zone Button Click: ${buttonText}`,
                        event_type: 'Engagement Event',
                        experience_type: 'SNAPS2'
                    }}
                    ref={prodOptButtonRef}
                    buttonType="tertiary"
                    size="medium"
                    className={
                        focusViewProductOptions
                            ? 'sps2-pdp_pSelector--selectors_btn-focus'
                            : 'sps2-pdp_pSelector--selectors_btn'
                    }
                    disabled={moreOptions?.displayTable === false}
                    onClick={() => {
                        // Params: isGetProductOptions, scrollToTable
                        handleProductSelectorRequest(
                            moreOptions?.displayTable,
                            moreOptions?.displayTable
                        )
                    }}
                >
                    {/* Param: displayLink */}
                    {getNumberOfOptions(moreOptions?.displayTable)}
                </Button>
            </div>
            {errors?.selectorsAjaxError && (
                <div className="sps2-pdp_ajaxError--centered mds-margin_small--bottom mds-margin_medium--top">
                    <Alert.InContext variant="error">
                        {translations.ajaxError}
                    </Alert.InContext>
                </div>
            )}
        </div>
    )
}

export default SelectorsContainer
