import React, { useState, useContext } from 'react'
import { StateContext } from '../../services/StateService'
import CTASection from './CTASection'
import ProductSelector from './ProductSelector'
import WhereToBuyController from './WhereToBuyController'
import ServiceLocator from './ServiceLocator'
import '../../css/PurchaseOptions.scss'

const PurchaseOptions = ({
    supportRef,
    supportSecondaryRef,
    supportTertiaryRef,
    productOptionsRef,
    prodOptButtonRef,
    getProductEndpoint,
    handleProductSelectorRequest
}) => {
    const { ctaInfo } = useContext(StateContext)
    const [displaySelectors, setDisplaySelectors] = useState(false)

    return (
        <div className="sps2-pdp_purchase--container">
            <ProductSelector
                prodOptButtonRef={prodOptButtonRef}
                handleProductSelectorRequest={handleProductSelectorRequest}
                displaySelectors={displaySelectors}
                setDisplaySelectors={setDisplaySelectors}
            />
            <WhereToBuyController
                displaySelectors={displaySelectors}
                getProductEndpoint={getProductEndpoint}
            />
            <CTASection
                productOptionsRef={productOptionsRef}
                prodOptButtonRef={prodOptButtonRef}
                supportRef={supportRef}
                supportSecondaryRef={supportSecondaryRef}
                supportTertiaryRef={supportTertiaryRef}
            />
            {ctaInfo?.serviceLocatorWidgetId && ctaInfo?.serviceLocatorSubId && (
                <ServiceLocator
                    widgetId={ctaInfo.serviceLocatorWidgetId}
                    subId={ctaInfo.serviceLocatorSubId}
                />
            )}
        </div>
    )
}

export default PurchaseOptions
